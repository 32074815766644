<script setup lang="ts">
import JamBaseNotificationWrapper from '../components/JamBaseNotificationWrapper.vue';

const links = [
    {
        label: 'Style Guide',
        uri: '/p/jobs/styleguide',
    },
    {
        label: 'Checkboxes',
        uri: '/p/jobs/styleguide/checkbox',
    },
    {
        label: 'ComboBox',
        uri: '/p/jobs/styleguide/combobox',
    },
    {
        label: 'DatePicker',
        uri: '/p/jobs/styleguide/date-picker',
    },
    {
        label: 'FileUpload',
        uri: '/p/jobs/styleguide/file-upload',
    },
    {
        label: 'Group',
        uri: '/p/jobs/styleguide/group',
    },
    {
        label: 'Infobox',
        uri: '/p/jobs/styleguide/infobox',
    },
    {
        label: 'Link',
        uri: '/p/jobs/styleguide/link',
    },
    {
        label: 'Notification',
        uri: '/p/jobs/styleguide/notification',
    },
    {
        label: 'Pill',
        uri: '/p/jobs/styleguide/pill',
    },
    {
        label: 'Section',
        uri: '/p/jobs/styleguide/section',
    },
    {
        label: 'Select Box',
        uri: '/p/jobs/styleguide/select',
    },
    {
        label: 'Table',
        uri: '/p/jobs/styleguide/table',
    },
    {
        label: 'Tabs',
        uri: '/p/jobs/styleguide/tabs',
    },
];
const route = useRoute();
const currentPath = computed(() => {
    let path = route.path;
    if (path.charAt(path.length - 1) === '/') {
        path = path.slice(0, path.length - 1);
    }
    return path;
});
</script>

<template>
    <div class="flex min-h-screen flex-col justify-stretch md:flex-row">
        <JamBaseNotificationWrapper />
        <div class="flex flex-col bg-gray-800 text-gray-100 shadow md:w-1/6">
            <div class="sticky top-0">
                <NuxtLink
                    class="my-5 block px-4 py-3 font-semibold transition-all hover:text-white"
                    :href="`/p/jobs`"
                >
                    Manage Job Ads
                </NuxtLink>
                <template v-for="(link, idx) in links" :key="'link-' + idx">
                    <NuxtLink
                        class="block border-b border-dotted border-gray-900 px-4 py-3 transition-all hover:bg-black/30"
                        :class="{
                            'bg-black/20': currentPath == link.uri,
                        }"
                        :href="link.uri"
                    >
                        {{ link.label }}
                    </NuxtLink>
                </template>
            </div>
        </div>
        <div class="px-5 py-4 md:w-5/6">
            <slot />
        </div>
    </div>
</template>
